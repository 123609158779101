var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MitchOS',{attrs:{"taskGroupBindings":[
    // max 10 - https://firebase.blog/posts/2019/11/cloud-firestore-now-supports-in-queries
    'backdrop',
    'debug',
    'lobby', // whoah whoah whoah, this messed up lobby show video big time, sync issues etc
    'scene',
    'screenSharePlayer',
    'system',
    'twitchPlayer',
    'youTubePlayer' ]}},[_c('TheStage',{attrs:{"exclude":"foreground"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }