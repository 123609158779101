<template>
  <MitchOS
    :taskGroupBindings="[
      // max 10 - https://firebase.blog/posts/2019/11/cloud-firestore-now-supports-in-queries
      'backdrop',
      'debug',
      'lobby', // whoah whoah whoah, this messed up lobby show video big time, sync issues etc
      'scene',
      'screenSharePlayer',
      'system',
      'twitchPlayer',
      'youTubePlayer',
    ]"
  >
    <!-- DEBUG -->
    <!-- <Debug /> -->

    <!-- THE STAGE -->
    <TheStage exclude="foreground" />
  </MitchOS>
</template>

<script>
  // import Debug from "@/components/MitchOS/Programs/Debug-v1/Debug.vue";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";
  import TheStage from "@/components/MitchOS/TheStage/TheStage.vue";

  const manifest = window.composeManifest([
    "./fonts/",
    // './img/',
    "./MitchOS/Programs/ScreenSharePlayer/",
    "./MitchOS/Programs/TwitchPlayer/",
    "./MitchOS/Programs/YouTubePlayer/",
    ".MitchOS/Backdrops/WallpaperIcons/",
  ]);

  export default {
    name: "BroadcastBackgroundView",
    components: {
      // Debug,
      MitchOS,
      TheStage,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    created() {
      //-----------------------------------------------------------------
      // EMIT - REQUEST PRELOADER
      // Each view has its own set of assets to load
      //-----------------------------------------------------------------

      this.$emit("request-preloader", {
        manifest,
      });

      // Bind youtube playlists
      this.$store.dispatch("youtube/bindPlaylists");
    },
  };
</script>
